import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
// import AppleStore from "../../atoms/icon/apple-store";
// import GooglePlay from "../../atoms/icon/google-play";
import SocialMediaIcons from "../social-media-icons";
import "./social-share.m.css";

const SocialShare = ({ googlePlayStore, appleStore }) => {
  return (
    <div styleName="wrapper" className="social-share-wrapper">
      <div styleName="app-store">
        <a href={appleStore} target="_blank" rel="noopener noreferrer">
          <img data-src="https://gumlet.assettype.com/kalaignarseithigal/2019-12/5a7daaa6-1fd6-4d0d-8a6e-af415c08f314/appstore.svg" />
          {/* <AppleStore /> */}
        </a>
        <a href={googlePlayStore} target="_blank" rel="noopener noreferrer">
          <img data-src="https://gumlet.assettype.com/kalaignarseithigal/2019-12/f4314553-0599-4182-9740-9caedf22a615/google_play.svg" />
          {/* <GooglePlay /> */}
        </a>
      </div>

      <div styleName="social-icons">
        <span styleName="follow-us">Follow Us</span>
        <span styleName="hyphen-symbol">|</span>
        <SocialMediaIcons />
      </div>
    </div>
  );
};

SocialShare.propTypes = {
  googlePlayStore: string,
  appleStore: string
};

function mapStateToProps(state) {
  return {
    googlePlayStore: get(state, ["qt", "config", "publisher-attributes", "googlePlayStore"], ""),
    appleStore: get(state, ["qt", "config", "publisher-attributes", "appleStore"], "")
  };
}

export default connect(
  mapStateToProps,
  () => ({})
)(SocialShare);
