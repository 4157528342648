import React from "react";
import { func, bool } from "prop-types";
import cx from "classnames";

import "./nav-bar-toggle-btn.m.css";

const NavBarToggleBtn = ({ onMegaMenuToggle, isMegaMenuOpen }) => {
  return (
    <button
      onClick={onMegaMenuToggle}
      styleName={cx("hamburger", isMegaMenuOpen ? "is-open" : "")}
      className="hamburger-wrapper"
    >
      <span styleName="line" />
      <span styleName="line" />
      <span styleName="line" />
    </button>
  );
};

NavBarToggleBtn.propTypes = {
  onMegaMenuToggle: func,
  isMegaMenuOpen: bool
};

export default NavBarToggleBtn;
