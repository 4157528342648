import React from "react";
import { createDfpAdComponent } from "./dfp-ad-impl";
import get from "lodash/get";

const ViewPortSizeMappingBanner720x90 = [
  { viewport: [768, 0], sizes: [[728, 90]] },
  { viewport: [300, 0], sizes: [[300, 250]] }
];

const ViewPortSizeMappingHorizontaldefault970x90 = [
  { viewport: [768, 0], sizes: [[970, 90]] },
  { viewport: [300, 0], sizes: [[320, 50]] }
];

const ViewPortSizeMappingHorizontaldefault300x600 = [
  { viewport: [768, 0], sizes: [[300, 600]] },
  { viewport: [300, 0], sizes: [[300, 250]] }
];

export const CONFIG = {
  mrec: {
    adUnit: "mrec",
    sizes: [[300, 250]]
  },
  mrec_2: {
    adUnit: "mrec_2",
    sizes: [[300, 250]]
  },
  mrec_3: {
    adUnit: "mrec_3",
    sizes: [[300, 250]]
  },
  HeaderAd: {
    adUnit: "HeaderAd",
    sizes: [[320, 50], [970, 90]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontaldefault970x90
  },
  Banner_1: {
    adUnit: "Banner_1",
    sizes: [[300, 250], [728, 90]],
    viewPortSizeMapping: ViewPortSizeMappingBanner720x90
  },
  Banner_2: {
    adUnit: "Banner_2",
    sizes: [[300, 250], [728, 90]],
    viewPortSizeMapping: ViewPortSizeMappingBanner720x90
  },
  Rectangle_1_300x600: {
    adUnit: "rectangle_1_300x600",
    sizes: [[300, 250], [300, 600]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontaldefault300x600
  }
};

const getPageName = state => {
  const pagetype = get(state, ["qt", "pageType"]);
  if (pagetype === "home-page") {
    return "home-page";
  } else if (pagetype === "section-page") {
    return get(state, ["qt", "data", "collection", "slug"]);
  } else if (pagetype === "story-page") {
    return get(state, ["qt", "data", "story", "sections", 0, "slug"]);
  }
};

const getStorySectionName = state => {
  const pagetype = get(state, ["qt", "pageType"]);
  if (pagetype === "story-page") {
    if (get(state, ["qt", "data", "story", ["story-template"]]) === null) {
      return "text";
    }
    return get(state, ["qt", "data", "story", ["story-template"]]);
  }
};

const DfpAdContent = createDfpAdComponent({
  defaultNetworkID: "21817236986",
  config: CONFIG,
  targeting: function(state) {
    const params = {
      environment: get(state, ["qt", "config", ["publisher-attributes"], "env"]),
      pageType: get(state, ["qt", "pageType"]),
      publisherName: get(state, ["qt", "config", "publisher-name"]),
      publisherId: get(state, ["qt", "config", "publisher-id"]) || 571,
      pageName: getPageName(state),
      storyType: getStorySectionName(state)
    };

    if (
      get(state, ["qt", "pageType"]) === "story-page" &&
      get(state, ["qt", "data", "story", "metadata", "sponsored-by"])
    ) {
      params.sponsor = get(state, ["qt", "data", "story", "metadata", "sponsored-by"]);
      return params.sponsor;
    }

    return params;
  }
});

const DfpAd = props => {
  return (
    <div>
      <DfpAdContent {...props} />
    </div>
  );
};

export default DfpAd;
