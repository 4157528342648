import React from "react";
import { oneOfType, object, array } from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import TopHeader from "../molecules/header";
import DfpAd from "../dfp-ad";

import "./header.m.css";

class HeaderBase extends React.Component {
  render() {
    const {
      menu: { headerLinks, specialHeaderLinks }
    } = this.props;

    return (
      <React.Fragment>
        <div styleName="adWrapper">
          <DfpAd adtype="HeaderAd" />
        </div>
        <div>
          <TopHeader headerList={headerLinks} specialHeaderList={specialHeaderLinks} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu"], [])
  };
}

HeaderBase.propTypes = {
  menu: oneOfType([object, array])
};

export const Header = connect(
  mapStateToProps,
  () => ({})
)(HeaderBase);
