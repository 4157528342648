import React from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, bool } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import FooterMenuGroup from "../../molecules/footer-menu-group";
import Logo from "../../atoms/icon/logo";
import SocialMediaIcons from "../../molecules/social-media-icons";
import QuintypeIcon from "../../atoms/icon/quintype-logo.svg";
import "./footer.m.css";

const staticItems = {
  poweredBy: "Powered by Quintype",
  url: "https://www.quintype.com/"
};

const FooterBase = ({ staticFooterLinks, footerMenuLinks, copyrightText }) => {
  return (
    <div styleName="wrapper" className="container">
      <div styleName="container">
        <div styleName="logo-wrapper">
          <Logo />
          <div styleName="social-icons">
            <div styleName="follow-us">Follow us</div>
            <SocialMediaIcons color="#ffffff" />
          </div>
        </div>
        <FooterMenuGroup links={footerMenuLinks} />
      </div>
      <div styleName="footer-links">
        {staticFooterLinks.map(
          (item, index) =>
            item.isExternalLink ? (
              <a href={item.completeUrl} key={index} styleName="static-links" target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            ) : (
              <Link href={"/" + item.completeUrl} key={index} styleName="static-links">
                {item.title}
              </Link>
            )
        )}
      </div>
      <div styleName="copyright">
        <div styleName="copyright-text">{copyrightText}</div>
        <div styleName="powered-by">
          <img src={`${assetify(QuintypeIcon)}`} alt="powered-by" />
          <a href={staticItems.url} target="_blank" rel="noopener noreferrer">
            {staticItems.poweredBy}
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    copyrightText: get(state, ["qt", "config", "publisher-settings", "copyright"], ""),
    staticFooterLinks: get(state, ["qt", "data", "navigationMenu", "staticFooterLinks"], []) || {},
    footerMenuLinks: get(state, ["qt", "data", "navigationMenu", "footerMenuLinks"], []) || {}
  };
};

FooterBase.propTypes = {
  copyrightText: string,
  footerMenuLinks: arrayOf(
    shape({
      isExternalLink: bool,
      completeUrl: string,
      title: string
    })
  ),
  staticFooterLinks: arrayOf(
    shape({
      completeUrl: string,
      title: string
    })
  )
};

const FooterRow = connect(mapStateToProps)(FooterBase);

export default FooterRow;
