import React from "react";
import { arrayOf, bool, string, shape } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import FooterRow from "../../rows/footer";
import "./styles.m.css";

const FooterBase = ({ footerMenuLinks }) => (
  <div styleName="footer">{footerMenuLinks && <FooterRow footerMenuLinks={footerMenuLinks} />}</div>
);

function mapStateToProps(state) {
  return {
    footerMenuLinks: get(state, ["qt", "data", "navigationMenu", "footerMenuLinks"], [])
  };
}

FooterBase.propTypes = {
  footerMenuLinks: arrayOf(
    shape({
      isExternalLink: bool,
      completeUrl: string,
      title: string
    })
  )
};

export const Footer = connect(
  mapStateToProps,
  null
)(FooterBase);
