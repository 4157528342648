import React from "react";
import { connect } from "react-redux";
import { bool, string, array, func } from "prop-types";
import cx from "classnames";
import get from "lodash/get";
import { Link } from "@quintype/components";
import SocialShare from "../../molecules/social-share";
import "./side-bar-menu.m.css";

const menuListView = (list, onMegaMenuToggle) => {
  return list.map((item, index) => {
    if (item.isExternalLink) {
      return (
        <li styleName="menu-item" key={index}>
          <a href={item.completeUrl} className="menu-item-link" target="_blank" rel="noopener noreferrer">
            <div onClick={onMegaMenuToggle}>{item.title}</div>
          </a>
        </li>
      );
    } else {
      return (
        <li styleName="menu-item" key={index}>
          <Link href={item.completeUrl} className="menu-item-link">
            <div onClick={onMegaMenuToggle}>{item.title}</div>
          </Link>
        </li>
      );
    }
  });
};

const SideBarMenu = props => {
  const headerList = get(props, ["headerList"], []);
  const specialHeaderList = get(props, ["specialHeaderList"], []);
  const wrapperStyle = cx("wrapper", "sidebar-menu", props.isMegaMenuOpen ? "is-open" : "");

  return (
    <div styleName={wrapperStyle}>
      <div>
        <ul styleName="menu-list">{menuListView(headerList, props.onMegaMenuToggle)}</ul>
        <ul styleName="menu-special-section-list">{menuListView(specialHeaderList, props.onMegaMenuToggle)}</ul>
      </div>

      <div styleName="social-icons-wrapper">
        <SocialShare />
      </div>
    </div>
  );
};

SideBarMenu.defaultProps = {
  color: "#fff"
};

SideBarMenu.propTypes = {
  isMegaMenuOpen: bool,
  headerList: array,
  specialHeaderList: array,
  color: string,
  socialLinks: array,
  onMegaMenuToggle: func
};

function mapStateToProps(state) {
  return {
    socialLinks: get(state, ["social-links"], [])
  };
}

export default connect(
  mapStateToProps,
  () => ({})
)(SideBarMenu);
