import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import TvIcon from "../../atoms/icon/tvicon.svg";

import "./tv-icon.m.css";

const TvIconBase = ({ liveTv }) => {
  return (
    <a href={liveTv} target="_blank" rel="noopener noreferrer">
      <div styleName="wrapper">
        <img src={`${assetify(TvIcon)}`} alt="live tv" />
      </div>
    </a>
  );
};

TvIconBase.propTypes = {
  liveTv: string
};

function mapStateToProps(state) {
  return {
    liveTv: get(state, ["qt", "config", "publisher-attributes", "liveTv"], "")
  };
}

export default connect(
  mapStateToProps,
  () => ({})
)(TvIconBase);
