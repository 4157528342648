import React from "react";
import { connect } from "react-redux";
import { string, number, object } from "prop-types";
import assetify from "@quintype/framework/assetify";
import get from "lodash/get";

import Facebook from "../../atoms/icon/facebook.svg";
import Youtube from "../../atoms/icon/youtube.svg";
import Twitter from "../../atoms/icon/twitter.svg";
import Instagram from "../../atoms/icon/instagram.svg";

import "./social-media-icons.m.css";

const SocialMediaIcons = ({ color = "#000", width = 24, height = 24, socialLinks = [], youtube }) => {
  const list = [];

  list.push({
    componentName: Facebook,
    url: socialLinks["facebook-url"],
    alt: "facebook"
  });

  list.push({
    componentName: Youtube,
    url: youtube,
    alt: "Youtube"
  });

  list.push({
    componentName: Twitter,
    url: socialLinks["twitter-url"],
    alt: "Twitter"
  });

  list.push({
    componentName: Instagram,
    url: socialLinks["instagram-url"],
    alt: "Instagram"
  });

  return (
    <ul styleName="icon-list" className="social-media-icon-wrapper">
      {list.map((item, index) => {
        const SocialMediaIcon = item.componentName;

        return (
          <li styleName="icon-item" key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={`${assetify(SocialMediaIcon)}`} alt={item.alt} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

SocialMediaIcons.propTypes = {
  color: string,
  width: number,
  height: number,
  socialLinks: object,
  youtube: string
};

function mapStateToProps(state) {
  return {
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    youtube: get(state, ["qt", "config", "publisher-attributes", "youtube"], "")
  };
}

export default connect(
  mapStateToProps,
  () => ({})
)(SocialMediaIcons);
