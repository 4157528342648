import React, { useEffect, useState } from "react";
import { array } from "prop-types";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
// import TitleLogo from "../../atoms/icon/title-logo";
import Logo from "../../atoms/icon/logo";
import MenuGroup from "../../atoms/menu-group";
import NavBarToggleBtn from "../../atoms/nav-bar-toggle-btn";
import SocialShare from "../../molecules/social-share";
import SideBarMenu from "../side-bar-menu";
import Search from "../../atoms/icon/search.svg";
import MobileSearch from "../../molecules/mobile-search";
import throttle from "lodash/throttle";

import "./header.m.css";

const TopHeader = ({ headerList, specialHeaderList }) => {
  const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const onMegaMenuToggle = () => setMegaMenuOpen(!isMegaMenuOpen);

  const onscrollSticky = e => {
    const header = document.getElementById("sticky-header");
    if (header) {
      const sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", throttle(onscrollSticky, 50));
    return () => {
      window.removeEventListener("scroll", onscrollSticky);
    };
  });

  return (
    <div styleName="header">
      {!isSearchOpen && (
        <div className="container">
          <div styleName="middle-wrapper">
            <Logo width={67} />
            <NavBarToggleBtn onMegaMenuToggle={onMegaMenuToggle} isMegaMenuOpen={isMegaMenuOpen} />
            <div styleName="mobile-search-wrapper">
              <img src={`${assetify(Search)}`} alt="search" onClick={() => setIsSearchOpen(!isSearchOpen)} />
            </div>

            <span styleName="title-logo">
              <Link href="/">
                <div styleName="desktop-logo-mode">
                  <img
                    data-src="https://gumlet.assettype.com/kalaignarseithigal/2019-12/58b26780-6b1f-4e3c-8d5d-865c2a46d311/text_logo.svg?format=webp&w=332"
                    alt="logo"
                    height="48"
                    width="332"
                  />
                </div>
                <div styleName="mobile-logo-mode">
                  <Logo />
                  <img
                    styleName="logo-text"
                    data-src="https://gumlet.assettype.com/kalaignarseithigal/2019-12/58b26780-6b1f-4e3c-8d5d-865c2a46d311/text_logo.svg?format=webp&w=190"
                    width="190"
                    height="20"
                  />
                </div>
              </Link>
            </span>

            <SocialShare />
          </div>
        </div>
      )}

      <div styleName="menu-sections" id="sticky-header">
        <SideBarMenu
          isMegaMenuOpen={isMegaMenuOpen}
          headerList={headerList}
          specialHeaderList={specialHeaderList}
          color="#fff"
          onMegaMenuToggle={onMegaMenuToggle}
        />
        <div>
          <MenuGroup headerList={headerList} specialHeaderList={specialHeaderList} />
        </div>
      </div>
      <div />

      {isSearchOpen && (
        <div styleName="search-box">
          <MobileSearch toggleSearch={() => setIsSearchOpen(!isSearchOpen)} />
        </div>
      )}
    </div>
  );
};

TopHeader.propTypes = {
  headerList: array,
  specialHeaderList: array
};

export default TopHeader;
