import React from "react";
import { Link } from "@quintype/components";
import "./footer-menu-column.m.css";
import { array } from "prop-types";

const FooterMenuColumn = ({ footerMenuColumn }) => {
  const childItems = footerMenuColumn.filter(item => !item["parent-id"]);

  return (
    childItems && (
      <div styleName="wrapper" className="wrapper">
        <ul>
          {childItems.map((childItem, index) => (
            <li key={index}>
              <Link href={childItem.completeUrl} styleName="child-menu-item">
                {childItem.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

FooterMenuColumn.propTypes = {
  footerMenuColumn: array
};
export default FooterMenuColumn;
