import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import FooterMenuColumn from "../../atoms/footer-menu-column/index";
import { array } from "prop-types";
import "./footer-menu-group.m.css";

const FooterMenuGroupBase = ({ links }) => {
  const parentLinks = links.filter(item => !item["parent-id"]);

  const generateMenuList = parentLink => {
    const menuList = links.filter(link => link["parent-id"] === parentLink.id);
    menuList.push(parentLink);
    return menuList;
  };

  const formMenuColumns = () => {
    return parentLinks.map((parentLink, index) => (
      <FooterMenuColumn key={index} footerMenuColumn={generateMenuList(parentLink)} />
    ));
  };

  return (
    <div styleName="wrapper" className="wrapper">
      {formMenuColumns()}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    links: get(state, ["qt", "data", "navigationMenu", "footerMenuLinks"], []) || {}
  };
};

const FooterMenuGroup = connect(mapStateToProps)(FooterMenuGroupBase);

FooterMenuGroupBase.propTypes = {
  links: array
};

export default FooterMenuGroup;
