import React from "react";
import { array, bool, string } from "prop-types";
import { connect } from "react-redux";
import Carousel from "nuka-carousel";
import get from "lodash/get";

import "./breaking-news-view.m.css";

function breakingNewsItem(story) {
  const cursorStyle = story.metadata && story.metadata["linked-story-slug"] ? "cursor-pointer" : "cursor-auto";

  return (
    <div styleName="breaking-news-content" key={story.id}>
      <h2 styleName="breaking-news-header" className="breaking-news">
        <a
          href={story.metadata && story.metadata["linked-story-slug"]}
          styleName={cursorStyle}
          target="_blank"
          rel="noopener noreferrer"
        >
          {story.headline}
        </a>
      </h2>
    </div>
  );
}

class BreakingNewsBase extends React.Component {
  componentDidUpdate() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));  // eslint-disable-line
    }, 0);
  }

  render() {
    const { pageType, breakingNews, breakingNewsLoaded } = this.props;

    // If breaking news not loaded then return null
    if (!breakingNewsLoaded) {
      return null;
    }

    // If breaking news is empty then return null
    if (breakingNews.length === 0) {
      return null;
    }

    const { list, isLiveTvFound } = this.getBreakingNews(breakingNews);
    let containerStyle = null;

    switch (pageType) {
      case "section-page":
        containerStyle = "container-section-page";
        break;
      case "story-page":
      case "search-page":
        containerStyle = "container-story-page";
        break;
      default:
        containerStyle = "container-home-page";
    }

    return (
      <div styleName={containerStyle}>
        <div styleName="wrapper">
          <div styleName={isLiveTvFound ? "breaking-news-title-live-tv" : "breaking-news-title-others"}>
            {isLiveTvFound ? "Live TV" : "Breaking News"}
          </div>
          <div styleName="carousel-container">
            <Carousel
              transitionMode="fade"
              cellAlign="left"
              slidesToShow={1}
              wrapAround={true}
              slideIndex={0}
              heightMode="current"
              slidesToScroll={1}
              withoutControls={true}
              autoplay={true}
              autoplayInterval={4000}
              initialSlideHeight={1}
            >
              {breakingNewsLoaded ? list.map(story => breakingNewsItem(story)) : <div />}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }

  // Filter the stories
  getBreakingNews(breakingNews) {
    let list = [];
    let isLiveTvFound = false;

    if (breakingNews.length === 1) {
      const story = { ...breakingNews[0] }; // Using spread operator else reference will be copied

      if (story.headline.startsWith("|")) {
        story.metadata["linked-story-slug"] = this.props.liveTv;
        story.headline = story.headline.substring(1); // Remove | character
        isLiveTvFound = true;
      }

      list.push(story);
    } else {
      // Filter and remove the live tv story if it starts with | character
      list = breakingNews.filter(s => !s.headline.startsWith("|"));
    }

    return {
      list,
      isLiveTvFound
    };
  }
}

function mapStateToProps(state) {
  return {
    pageType: get(state, ["qt", "pageType"], ""),
    liveTv: get(state, ["qt", "config", "publisher-attributes", "liveTv"], "")
  };
}

BreakingNewsBase.propTypes = {
  breakingNews: array,
  breakingNewsLoaded: bool,
  pageType: string,
  liveTv: string
};

export const BreakingNewsView = connect(
  mapStateToProps,
  null
)(BreakingNewsBase);
