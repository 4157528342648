import React from "react";
import { func } from "prop-types";
import { SearchBox } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import Close from "../../atoms/icon/close.svg";

import Search from "../../atoms/icon/search-white.svg";

import "./mobile-search.m.css";

const searchForm = ({ children }) => {
  return [
    <label styleName="qt-search-label" htmlFor="searchForm" key="1">
      {children}
    </label>,
    <button type="submit" key="2" styleName="search-button">
      <img src={`${assetify(Search)}`} alt="search" />
    </button>
  ];
};

function MobileSearch({ toggleSearch }) {
  const toggleSearchBox = () => toggleSearch();

  return (
    <React.Fragment>
      <SearchBox
        styleName="qt-search__form"
        template={searchForm}
        inputId="searchForm"
        inputClassName="qt-search__form-input"
        onSubmitHandler={toggleSearchBox}
        onEscape={toggleSearchBox}
        placeholder="Search Here"
      />
      <div styleName="close-icon">
        <img src={`${assetify(Close)}`} alt="close" onClick={toggleSearchBox} />
      </div>
    </React.Fragment>
  );
}

MobileSearch.propTypes = {
  toggleSearch: func
};

export default MobileSearch;
