import React from "react";
import { number } from "prop-types";

const OnScrollLogo = ({ width = 61, height = 40 }) => {
  const url = `https://gumlet.assettype.com/kalaignarseithigal/2019-05/1734a6ac-513e-4320-be8c-f94109db0a50/logo2.png?w=${width}&h=${height}`;

  return <img src={url} alt="on-scroll-logo" className="scroll-logo" width={width} />;
};

OnScrollLogo.propTypes = {
  width: number,
  height: number
};

export default OnScrollLogo;
