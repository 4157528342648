import React, { useState } from "react";
import { array } from "prop-types";
import { SearchBox, Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import SearchWhite from "../../atoms/icon/search-white.svg";
import OnScrollLogo from "../../atoms/icon/onscroll-logo";

import "./menu-group.m.css";

const menuListView = list => {
  return list.map((item, index) => {
    if (item.isExternalLink) {
      return (
        <li styleName="menu-list-item" key={index}>
          <a href={item.completeUrl} className="menu-item-link" target="_blank" rel="noopener noreferrer">
            {item.title}
          </a>
        </li>
      );
    } else {
      return (
        <li styleName="menu-list-item" key={index}>
          <Link href={item.completeUrl} className="menu-item-link">
            {item.title}
          </Link>
        </li>
      );
    }
  });
};

const searchForm = ({ children }) => {
  return [
    <label styleName="qt-search-label" htmlFor="searchForm" key="1">
      {children}
    </label>,
    <button type="submit" key="2" styleName="search-button">
      Search
    </button>
  ];
};

function MenuGroup({ headerList, specialHeaderList }) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearchBox = () => setIsSearchOpen(!isSearchOpen);

  return (
    <div styleName="wrapper">
      <div styleName="container">
        <nav styleName="navbar" className="navbar-wrapper navbar-on-scroll">
          <Link href="/" className="logo-on-scroll">
            <OnScrollLogo width="30" />
          </Link>
          <div className="menu-list-wrapper">
            <ul styleName="menu-list">{menuListView(headerList || [])}</ul>
            <ul styleName="menu-special-section-list">{menuListView(specialHeaderList || [])}</ul>
          </div>

          <div className="menu-search">
            <img src={`${assetify(SearchWhite)}`} alt="search" onClick={toggleSearchBox} />
          </div>
        </nav>
      </div>

      {isSearchOpen && (
        <div styleName="container">
          <div styleName="search-wrapper-with-close">
            <SearchBox
              styleName="qt-search__form"
              template={searchForm}
              inputId="searchForm"
              inputClassName="qt-search__form-input"
              onSubmitHandler={toggleSearchBox}
              onEscape={toggleSearchBox}
              placeholder="Search Here"
            />
          </div>
        </div>
      )}
    </div>
  );
}

MenuGroup.propTypes = {
  headerList: array,
  specialHeaderList: array
};

export default MenuGroup;
