import React from "react";
import { number } from "prop-types";

const Logo = ({ width = 67, height = 89 }) => {
  const url = `https://gumlet.assettype.com/kalaignarseithigal/2019-05/df6b46d7-2040-47df-bc77-7914dd82dd2d/logo.png?w=${width}&h=${height}`;

  return <img src={url} alt="main-logo" className="logo-wrapper" width={width} />;
};

Logo.propTypes = {
  width: number,
  height: number
};

export default Logo;
